import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Sources from '../dashboard/sourcesLantia/Sources'
import { getData } from '../../redux/actions/trendAction';
import { useDispatch, useSelector } from 'react-redux';
import ColorTitle from '../colorTitle/ColorTitle'
import NewsCarrousel from '../dashboard/newsCarrousel/NewsCarrousel'
import VideosCarrousel from '../dashboard/videosCarrousel/VideosCarrousel'
import AnnouncementCarrousel from '../dashboard/announcementCarrousel/AnnouncementCarrousel'
import EventsCarrousel from '../dashboard/eventsCarrousel/EventsCarrousel'
import PatentTable from '../dashboard/patentTable/PatentTable'
import StartupSlider from '../dashboard/startupsSlider/StartupSlider'
import WorldEconomy from '../dashboard/worldEconomy/WorldEconomy'
import ReactTooltip from "react-tooltip";
import ArticlesSlider from '../dashboard/articlesSlider/ArticlesSlider'
import Trends from '../dashboard/trends/Trends'
import GreenBox from '../loadingTemplates/greeBox/GreenBox'
import IndustriesLoading from '../loadingTemplates/industries/Industries'
import industries from '../../constants/industries'
import LeadersCarrousel from '../dashboard/leadersCarrousel/LeadersCarrousel'
import leyend from '../../constants/leyend'
import { useHistory } from 'react-router-dom';
import Cvlac from './cvlac/Cvlac';
import Gplac from './gplac/Gplac';
import Instlac from './instlac/Instlac';
import './dashboard.css'

//componente principal
const Dashboard = (props) => {
    console.log('props', props);
    const meaning = useRef();
    const imply = useRef();
    const impact = useRef();
    const data = useSelector(state => state.trend.trend);
    const trendSelected = useSelector(state => state.trend.trendSelected);
    const history = useHistory();

    const dispatch = useDispatch();

    const [moreText, setMoreText] = useState('');
    const [buttonsUp, setButtonsUp] = useState('');
    const [tooltip, setTooltip] = useState('')
    const [translateTrend, setTranslateTrend] = useState('');
    const [heightBox, setHeightBox] = useState({ first: null });

    useEffect(() => {
        getTrend();
    }, [trendSelected])

    //se obtiene la tendencia seleccionada
    const getTrend = async () => {
        try {
            dispatch(getData(trendSelected));
        } catch (error) {

        }
    }

    const viewMore = (field) => {
        setMoreText(field === moreText ? '' : field)
    }

    //redirige al carrousel 
    //cuando cierro una tendencia
    const close = () => {
        history.push(`/carrousel`);
    }

    return (
        <div className="dashboardComponent">
            <div className='returnPl'>
                <a href="https://lantiasas.com/homeApp" style={{textDecoration:'none', color:'#fff'}} target="_blank">Regresar a la Plataforma lantia</a>
            </div>
            <div onClick={close} style={{ position: 'fixed', width: '100%' }}>
                <div style={{ width: '20px', float: 'right', marginRight: '20px', cursor: 'pointer', color: 'var(--grayOne)', fontSize: '30px' }}>x</div>
            </div>
            <div className="header">
                <h1>{trendSelected.toUpperCase()}</h1>
            </div>

            <div style={{ display: 'inline-flex', width: '100%' }}>
                <div className="definitionTrend">
                </div>
                <div className="definition">
                    <div style={{ paddingLeft: '15px', paddingTop: '20px' }} className="row">
                        <div className="col-7 col-md-7 col-lg-7 pl-0" style={{ display: 'inline-flex' }}>
                            <div style={{ marginTop: '50px' }}>
                                <div style={{ paddingLeft: '20px' }}>
                                    <ColorTitle name={'INDUSTRIAS MÁS RELEVANTES PARA LA TENDENCIA'} color={'#8bbe1b'} icon={'industry'} width={25} height={25} widthTitle={380} />
                                    <div className="firstBlueSquare" style={{ float: 'left', display: 'inline-flex', height: '50px', width: '100%', paddingTop: '15px', paddingLeft: '5px' }}>
                                        {data.length !== 0 ?
                                            industries.map(industry => {
                                                let industryCheck = data.industries.find(indu => indu.name === industry.name);
                                                return (<div style={{ display: 'inline-flex' }}>
                                                    <ReactTooltip id="industryName" place="top" effect="solid">
                                                        {tooltip}
                                                    </ReactTooltip>
                                                    <img onMouseOver={() => setTooltip(industry.name)} data-tip data-for="industryName" src={require(`../../images/ui/icons/industry/${industry.icon}.png`).default} width="14" height="14" />
                                                    <div className="circleCheck">
                                                        {industryCheck !== undefined && <div className="industryOk"></div>}
                                                    </div>
                                                </div>)
                                            }) :
                                            <IndustriesLoading />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 col-md-5 col-lg-5" style={{ display: 'inline-flex' }}>
                            <div style={{ marginLeft: '20%' }}>
                                {data.length !== 0 && <div>
                                    <div style={{ color: '#0073af', fontSize: '15px', zIndex: '50', position: 'relative', transform: 'translate(10px, 38px)' }}>
                                        <ReactTooltip id="leyendUrgency" place="top" effect="solid">
                                            {tooltip}
                                        </ReactTooltip>
                                        <div style={{ display: 'inline-flex' }}> <div style={{ marginRight: '10px' }}>Materialización</div> <img onMouseOver={() => setTooltip(leyend.urgency)} data-tip data-for="leyendUrgency" src={require(`../../images/ui/help.png`).default} width="20" height="20" /></div>
                                    </div><div className="urgency">
                                        {console.log('data.urgency', data.urgency)}
                                        <img className="level" style={{ transform: `translate(${-30 + (data.urgency * 6.7)}px, 50px )` }} src={require(`../../images/ui/level.png`).default} width="15" height="60" />
                                        <img className="urgencyImage" src={require(`../../images/ui/urgency.png`).default} width="150" height="60" />
                                        <div>
                                            <div className="materialization">Años para materializar la tendencia</div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            {data.length !== 0 &&
                                <div>
                                    <div style={{ display: 'inline-flex', marginLeft: '30px' }}>
                                        <ReactTooltip id="leyend" place="top" effect="solid">
                                            {tooltip}
                                        </ReactTooltip>
                                        <div style={{ color: '#0073af', fontSize: '15px', marginRight: '10px' }}>Mapa de calor</div>
                                        <img onMouseOver={() => setTooltip(leyend.hotMap)} data-tip data-for="leyend" src={require(`../../images/ui/help.png`).default} width="20" height="20" />
                                    </div>
                                    <div style={{ color: '#0073af', fontSize: '15px', marginLeft: '60px' }} className="probabilidadImpacto">
                                        {console.log('data.probability_impact', data.probability_impact)}
                                        <img style={{ transform: `translate(${-58 + (parseInt(data.probability_impact.substr(0, 1)) * 13)}px, ${(114 - (parseInt(data.probability_impact.substr(2, 1)) * 12))}px)` }}
                                            className="view" src={require(`../../images/ui/mira.png`).default} width="20" height="20" />
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                    <br />
                    <div style={{ paddingLeft: '30px', paddingRight: '30px' }} className="row">
                        <div className="col-4 col-md-4 col-lg-4">
                            <ColorTitle name={'¿QUÉ ES?'} color={'#8bbe1b'} icon={'descriptionIcon'} width={25} height={25} widthTitle={120} />
                            <div style={{ display: 'inline-flex', width: '100%' }}>
                                <div style={{ width: '100%', height: '70vh', paddingTop: '15px' }} className="greenSquare" ref={meaning}>
                                    {data.length !== 0 ?
                                        <div style={{ height: '65vh' }} className="cifrasDatosBox">
                                            <p className="text">{moreText === 'DESCRIPCIÓN' ? data.meaning : data.meaning}</p>
                                        </div>
                                        :
                                        <div>
                                            <GreenBox />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                            <ColorTitle name={'¿QUÉ IMPLICA?'} color={'#8bbe1b'} icon={'implicacionesIcon'} width={25} height={25} widthTitle={160} />
                            <div style={{ display: 'inline-flex', width: '100%' }}>
                                <div style={{ height: '70vh', paddingTop: '15px' }} className="greenSquare" ref={imply}>
                                    {data.length !== 0 ?
                                        // <p className="text">{moreText === 'IMPLICACIONES' ? data.implying : data.implying}</p> :
                                        <div style={{ height: '65vh' }} className="cifrasDatosBox">
                                            {data.implying.split('$%)#@¿=').map(impli => {
                                                return <p> ● {impli}</p>
                                            })}
                                        </div> :
                                        <div>
                                            <GreenBox />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                            <ColorTitle name={'¿CUÁL ES SU IMPACTO?'} color={'#8bbe1b'} icon={'impactIcon'} width={25} height={25} widthTitle={210} />
                            <div style={{ display: 'inline-flex', width: '100%' }}>
                                <div style={{ height: '70vh', paddingTop: '15px' }} className="greenSquare" ref={impact}>
                                    {data.length !== 0 ?
                                        <div style={{ height: '65vh' }} className="cifrasDatosBox">
                                            <p className="text">{moreText === 'IMPACTO' ? data.impact : data.impact}</p>
                                        </div> :
                                        <div>
                                            <GreenBox />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: '30px', paddingRight: '30px' }} className="row">
                        <div className="col-4 col-md-4 col-lg-4">
                            <ColorTitle name={'¿CUALES SON LAS CIFRAS Y DATOS?'} color={'#8bbe1b'} icon={'cifrasDatosIcon'} width={15} height={15} widthTitle={290} />
                            <div style={{ display: 'inline-flex' }}>
                                <div style={{ height: '400px', paddingTop: '20px', width: '98%' }} className="firstBlueSquare">
                                    {data.length !== 0 ?
                                        <div className="text cifrasDatosBox">
                                            <div>
                                                <img style={{ marginLeft: '10px' }} className="urgencyImage" src={require(`../../images/cifrasDatos/${data.figures_facts_path}`).default} width="250" height="90" />
                                                <p style={{ textAlign: 'justify' }}>{data.figures_facts_description}</p>
                                            </div>
                                            <p style={{ textAlign: 'justify' }}>{data.figures_facts_description}</p>
                                        </div> :
                                        <div>
                                            <GreenBox />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                            <ColorTitle name={'¿CUALES SON LOS REFERENTES?'} color={'#8bbe1b'} icon={'leaderIcon'} width={15} height={15} widthTitle={270} />
                            <div style={{ height: '400px', paddingBottom: '50px' }} className="firstBlueSquare">
                                <LeadersCarrousel />
                            </div>

                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                            <ColorTitle name={'PARA SABER MÁS'} color={'#8bbe1b'} icon={'linkIcon'} width={15} height={15} widthTitle={180} />
                            <div style={{ width: '100%', height: '400px' }} className="firstBlueSquare">
                                {data.length !== 0 ?
                                    <div style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto', paddingTop: '1px' }}>
                                        {data.links.map(link => {
                                            return (
                                                <div style={{ marginTop: '20px', cursor: 'pointer', width: '200px', display: 'block' }}>
                                                    <img src={require(`../../images/ui/icons/pdfIcon.png`).default} width="80" height="80" />
                                                    <br />
                                                    <a target="_blank" href={link.path}>{link.path.substr(8, 30).split('/')[0]}</a>
                                                </div>
                                            );
                                        })}
                                    </div> :
                                    <div>
                                        <GreenBox />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div style={{ display: 'inline-flex', width: '100%', marginTop: '50px' }}>
                <div className="exploreTrend">
                </div>
                <div className="explore">
                    <div style={{ paddingLeft: '12px' }} className="row">
                        <div className="col-8 col-md-8 col-lg-8">
                            <ColorTitle name={'NOTICIAS'} color={'var(--blueThree)'} icon={'newsIcon'} width={15} height={15} widthTitle={130} />
                            <div style={{ height: '300px' }} className="firstBlueSquare">
                                <NewsCarrousel />
                            </div>
                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                            <ColorTitle name={'VIDEOS'} color={'var(--blueThree)'} icon={'videoIcon'} width={15} height={15} widthTitle={130} />
                            <div style={{ height: '300px' }} className="firstBlueSquare">
                                <VideosCarrousel />
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: '12px' }} className="row">
                        <div className="col-8 col-md-8 col-lg-8">
                            <ColorTitle name={'PATENTES'} color={'var(--blueThree)'} icon={'patentIcon'} width={15} height={15} widthTitle={130} />
                            <div className="firstBlueSquare" style={{ height: 'auto', padding: '10px', wordBreak: 'break-all' }}>
                                <PatentTable />
                            </div>
                        </div>

                        <div className="col-4 col-md-4 col-lg-4">
                            <ColorTitle name={'ARTÍCULOS'} color={'var(--blueThree)'} icon={'articlesIcon'} width={15} height={15} widthTitle={130} />
                            <div className="firstBlueSquare" style={{ height: '520px', paddingTop: '10px' }}>
                                <ArticlesSlider />
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <ColorTitle name={'STARTUPS'} color={'var(--blueThree)'} icon={'startupsIcon'} width={15} height={15} widthTitle={130} />
                            <div className="firstBlueSquare" style={{ height: '40vh', padding: '20px' }}>
                                <StartupSlider />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <ColorTitle name={'ECONOMIA'} color={'var(--blueThree)'} icon={'startupsIcon'} width={15} height={15} widthTitle={130} />
                            <div className="firstBlueSquare map_information_topics" style={{ height: '70vh', padding: '20px' }}>
                                <WorldEconomy />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <ColorTitle name={'STARTUPS'} color={'#013B54'} icon={'startupsIcon'} width={15} height={15} widthTitle={130} />
                            <div className="firstBlueSquare" style={{ height: '40vh', padding: '20px' }}>
                                <StartupSlider />
                            </div>
                        </div>
                    </div> */}
                    {/* <div style={{ paddingLeft: '12px' }} className="row">
                <div className="col-6 col-md-6 col-lg-6">
                    <ColorTitle name={'EVENTOS'} color={'#013B54'} icon={'eventsIcon'} width={15} height={15} />
                    <div className="firstBlueSquare" >
                        <EventsCarrousel />
                    </div>
                </div>
                <div className="col-6 col-md-6 col-lg-6">
                    <ColorTitle name={'CONVOCATORIAS'} color={'#013B54'} icon={'announcementIcon'} width={15} height={15} />
                    <div className="firstBlueSquare" >
                        <AnnouncementCarrousel />
                    </div>
                </div>
                <div className="col-4 col-md-4 col-lg-4">

                </div>
            </div> */}
            
            {/*     </div>
            </div> */}



            {/* <div style={{ display: 'inline-flex', width: '100%', marginTop: '50px' }}>
                <div className="networkTrend">
                </div>
                <div className="network">
                    <div style={{ paddingLeft: '12px' }} className="row"> */}
                        {/* <div className="col-12 col-md-6 col-lg-6">
                            <ColorTitle name={'GRUPOS DE INVESTIGACIÓN'} color={'var(--blueFive)'} icon={'gplac'} width={15} height={15} widthTitle={250} />
                            <div className="firstBlueSquare" style={{ height: '400px', paddingTop: '10px' }}>
                                <Gplac />
                            </div>
                        </div> */}
                       {/*  <div className="col-12 col-md-6 col-lg-6">
                            <ColorTitle name={'INVESTIGADORES'} color={'var(--blueFive)'} icon={'cvlac'} width={15} height={15} widthTitle={170} />
                            <div className="firstBlueSquare" style={{ height: '400px', paddingTop: '10px' }}>
                                <Cvlac />
                            </div>
                        </div> */}

                        {/* <div className="col-4 col-md-4 col-lg-4">
                            <ColorTitle name={'INSTITUCIONES'} color={'var(--blueFive)'} icon={'institutions'} width={15} height={15} widthTitle={170} />
                            <div className="firstBlueSquare" style={{ height: '750px', padding: '10px', wordBreak: 'break-all' }}>
                                <Instlac />
                            </div>
                        </div> */}
                    {/* </div>
                </div>
            </div> */}




            

            <div style={{ paddingLeft: '12px', marginTop: '20px' }} className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <ColorTitle name={'TENDENCIAS'} color={'var(--orangeOne)'} icon={'trendIcon'} width={15} height={15} widthTitle={160} />
                    <div className="firstBlueSquare" style={{ height: 'auto', border: '1px solid var(--orangeOne)', width: '98.7%', paddingRight: '20px', paddingLeft: '20px' }} >
                        <Trends />
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footerText">www.vtsas.co @vtsas vtsasco</div>
                <div className="footerText">Términos de servicio | Politica de datos | © 2021 VTSAS- Lantia. Todos los derechos reservados. </div>
            </div>
        </div>
    );
}

export default Dashboard;
