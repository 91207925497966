const industries = [
    { 'name': 'Agropecuario', 'icon': 'agropecuario' }, 
    { 'name': 'Comercio', 'icon': 'comercio' }, 
    { 'name': 'Gobierno y defensa', 'icon': 'gobiernoDefensa' }, 
    { 'name': 'Transporte', 'icon': 'transporte' }, 
    { 'name': 'Textil', 'icon': 'textil' }, 
    { 'name': 'Minas y Petroleo', 'icon': 'minasPetroleo' }, 
    { 'name': 'Comunicaciones', 'icon': 'comunicaciones' }, 
    { 'name': 'Arte y entretenimiento', 'icon': 'arteEntretenimiento' },
    { 'name': 'Salud y asistencia', 'icon': 'saludAsistencia' }, 
    { 'name': 'Manufactura', 'icon': 'manufactura' },
    { 'name': 'Servicios Publicos', 'icon': 'serviciosPublicos' }, 
    { 'name': 'Financiero y seguros', 'icon': 'financieroSeguros' }, 
    { 'name': 'Educacion', 'icon': 'educacion' }, 
    { 'name': 'Turismo y hoteleria', 'icon': 'turismoHoteleria' }, 
    { 'name': 'Automotriz', 'icon': 'automotriz' }, 
    { 'name': 'Construccion', 'icon': 'construccion' }, 
    { 'name': 'Alimentos procesados', 'icon': 'alimentosProcesados' }, 
    { 'name': 'Inmobiliario', 'icon': 'inmobiliario' },
    { 'name': 'Actividades profesionales', 'icon': 'actividadesProfesionales' }, 
    { 'name': 'Energia', 'icon': 'energia' }];
    export default industries;