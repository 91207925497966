import {AUTHENTICATED} from '../types/auth';

//state
const initialState = {
    autenticated: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                autenticated: action.payload,
            }

        default:
            return state;
    }
}