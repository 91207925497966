import React, { useState, useEffect } from 'react'
import './datosTab.css';
//import translateText from '../../../../../../../hooks/useTranslate'
import { useSelector } from 'react-redux';


const Datos = ({ translate, patent }) => {

    const [abstract, setAbstract] = useState(patent.abstract_en);

    useEffect(() => {
        if (!translate) {
            setAbstract(patent.abstract_en)
            return;
        }

        // translateText(abstract).then(response => {
        //     setAbstract(response)
        // });

    }, [translate, patent])

    return (
        <div>
            <div className="bodyTab">
                <div style={{ marginLeft: '20px' }}>
                    <div style={{ float: 'left', display: 'inline-flex' }}><strong style={{ color: 'gray', marginRight:'10px' }}>N.° de publicación: </strong>{patent.id}</div>
                    <br />
                    <div style={{ float: 'left', display: 'inline-flex' }}><strong style={{ color: 'gray' }}>IPC: </strong>{patent.ipcr && patent.ipcr.map(x => <p>{x}, </p>)}</div>
                    <br />
                    <div style={{ float: 'left', display: 'inline-flex' }}><strong style={{ color: 'gray' }}>Inventores: </strong>{patent.inventors_name ? patent.inventors_name[0] : ''}</div>
                    <br />
                </div>

                <hr />

                <div className="row">
                    <div style={{ display: 'inline-flex', float: 'left', marginLeft: '35px' }}>
                        <h6>Título:</h6>
                        <p>{patent.hasOwnProperty('invention_title') && patent.invention_title[0]}</p>
                        <p>{patent.hasOwnProperty('invention_title_en') && patent.invention_title_en[0]}</p>
                    </div>
                </div>

                <div className="row">
                    <h6 style={{ float: 'left', width: '135px' }}>Abstract:</h6>
                </div>
                <div className="abstract">

                    {Array.isArray(abstract) ?
                        abstract.map(paragraph => {
                            return (
                                <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                    {paragraph}
                                </p>
                            );
                        }) :
                        <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                            {abstract}
                        </p>
                    }
                    {/* <p style={{ color: 'black' }}>{patent.abstract_en}</p> */}
                </div>
            </div>
        </div>
    )
}

export default Datos
