import React from 'react'
import './video.css'

const Video = ({ props }) => {
    console.log('props video', props);
    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12 headerArticleItem">
                    <div style={{ float: 'left', marginLeft: '20px', borderBottom: '1px solid gray', width: '95%' }}>
                        <div style={{ display: 'inline-flex', float: 'left', padding: '10px' }}>
                            <img src={require(`../../../../images/ui/icons/videoIcon.png`).default} alt="Girl in a jacket" width="25" height="25" />
                            <div style={{ float: 'left', marginLeft: '20px' }}>Video</div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                <div style={{ marginLeft: '50px' }} className="col-12 col-md-8">
                    <strong><p>{props.title[0]}</p></strong>
                </div>
            </div>
            <div className="row">
                <iframe
                    style={{marginLeft:'15%', marginRight:'15%', width:'70%', height:'50vh'}}
                    className="iframe"
                    src={`https://www.youtube.com/embed/${props._id}?rel=0&autoplay=1`}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
            <br />
            <div style={{ marginLeft: '50px' }} className="row">
                <p className="sourceAndDate"><strong>Fuente: </strong>Youtube</p>
            </div>
            <div style={{ marginLeft: '50px' }} className="row">
                <p className="sourceAndDate"><strong>Fecha de publicación: </strong>{props.datePublication.toString().substr(0, 10)}</p>
            </div>
            <div style={{ marginLeft: '50px' }} className="row">
                <p className="description"><strong>Descripción: </strong></p>
            </div>
            <div style={{ marginLeft: '50px', marginRight:'50px', textAlign:'justify', marginBottom:'50px' }} className="row">
                {props.description[0]}
            </div>
        </div>
    )
}

export default Video
