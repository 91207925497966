import { AUTHENTICATED } from '../types/auth';
import cookie from 'react-cookies'
import axios from 'axios';
import clienteAxios from '../../config/axios';
import axiosLogin from '../../config/axiosLogin';

export function loginAction(user) {
    return async (dispatch) => {
        try {

            let data = new FormData();
            data.append('email', user.email);
            data.append('password', user.password);

            //endpoint de la bd
            //const response = await clienteAxios.post('login', data);
            console.log('ascsasdsadsadsad');
            const response = await axiosLogin.post('login', data);
            if (response.data.db[0].db == 'PL') {
                console.log('token', response);
                const maxAge = 3600 * 24 * 15;
                const expires = new Date()
                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                cookie.save('token', response.data.message, { path: "/", expires, maxAge });
                dispatch(loginSuccess(true));

            } else if (response.data.message) {
                dispatch(loginSuccess(false));
                return 'invalid';
            }
        } catch (error) {
            console.log(error);
            dispatch(loginSuccess(false));
            alert('usuario o contraseña no validos')
            return 'error';
        }
    }
}

//funcion para loguearse con el token guardado
export function loginTokenAction(token){
    return async(dispatch) => {
        try {
            //llama endpoint login con token
            token = token.slice(7).trim()
            axiosLogin.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await axiosLogin.post('login/token');
            if (response.data.db[0].db.includes('PL')) {
                clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                const maxAge = 3600 * 24 * 15;
                const expires = new Date()
                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                cookie.save('token', token, { path: "/", expires, maxAge });
                dispatch(loginSuccess(true));
            }
           
        } catch (error) {
            return false;
        }
    }
}


const loginSuccess = authFlag => ({
    type: AUTHENTICATED,
    payload: authFlag
});