import React from 'react'
import './article.css'


const Article = ({ props }) => {
    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12 headerArticleItem">
                    <div style={{ float: 'left', marginLeft: '20px', borderBottom: '1px solid gray', width: '95%' }}>
                        <div style={{display:'inline-flex', float:'left', padding:'10px'}}>
                            <img src={require(`../../../../images/ui/icons/articles.png`).default} alt="Girl in a jacket" width="25" height="25" />
                            <div style={{ float: 'left', marginLeft:'20px' }}>Artículo</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bodyArticleItem">
                <div style={{ float: 'left' }}><strong>{props.title}</strong></div>
                <br />
                <div>Fecha de publicación: {props.datePublication.substr(0,10)}</div>
                <br />
                <br />
                <div style={{borderTop:'1px solid gray', paddingTop:'20px', height:'50vh', overflowY:'scroll'}}>{props.description}</div>
            </div>
        </div>
    )
}

export default Article
