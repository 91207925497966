import React, { useState, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import cookie from 'react-cookies'

//redux
import { useDispatch } from 'react-redux';
import { loginTokenAction } from '../../../redux/actions/authAction'


const ProtectedRoute = ({ ...props }) => {
    //componente prop
    const Component = props.component;
    const location = useLocation();

    //states
    const [wait, setWait] = useState(0);

    //redux
    const dispatch = useDispatch();
    const autenticated = useSelector(state => state.auth.autenticated);


    if (!cookie.load('token') || !autenticated) {
        return <Redirect to={{ pathname: '/login' }} />
    }
    else if (autenticated) {
        return <Component />
    }
}

export default ProtectedRoute;