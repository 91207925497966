import { useSelector } from 'react-redux';
import processData from '../../../helpers/ProccessData'
import { useHistory } from 'react-router-dom';
import { STARTUP, VIDEO, ANNOUNCEMENT, NEW, EVENT, PATENT, ARTICLE } from '../../../constants/categories'
import Patent from '../itemOfSourceComponents/patent/Patent.js'
import News from '../itemOfSourceComponents/new/New'
import Startups from '../itemOfSourceComponents/startup/Startups'
import Article from '../itemOfSourceComponents/article/Article'
import Video from '../itemOfSourceComponents/video/Video'
import volver from '../../../images/ui/icons/comeBack.png'
import './showResult.css';

const ShowResult = () => {

    const resultSelected = processData([useSelector(state => state.trend.resultSelected)]);
    const history = useHistory();

    const comeBack = () => {
        try {
            history.goBack()
        } catch (error) {

        }

    }

    return (
        <div>
            <div className="itemOfResults">
                <div onClick={comeBack} className="headerItem">
                    <div style={{ width: '9rem', display: 'inline-flex', float: 'right' }}><img src={volver} alt="" width='30' height='30' /><h6 style={{ margin: '5px 0 0 10px' }}>Volver</h6></div>
                </div>
                {resultSelected[0].category === PATENT &&
                    <Patent props={resultSelected[0]} />
                }
                {resultSelected[0].category === NEW &&
                    <News props={resultSelected[0]} />
                }
                {resultSelected[0].category === STARTUP &&
                    <Startups props={resultSelected[0]} />
                }
                 {resultSelected[0].category === ARTICLE &&
                    <Article props={resultSelected[0]} />
                }
                 {resultSelected[0].category === VIDEO &&
                    <Video props={resultSelected[0]} />
                }
            </div>
        </div>
    );
}
export default ShowResult;