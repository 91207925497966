import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import './description.css'
//import translateText from '../../../../../../../hooks/useTranslate'

const DescriptionTab = ({ translate, patent }) => {
    
    const [description, setDescription] = useState(patent.descriptions_en);

    useEffect(() => {
        if (!translate) {
            setDescription(patent.descriptions_en)
            return;
        }

        //  translateText(description).then(response => {
        //     setDescription(response)
        // });
        
    }, [translate, patent])

    return (
        <div className='descriptionTabMain'>
            
            <div style={{ overflowY: 'scroll', height: '60rem', color: 'black' }}>
                {description &&
                    description.map(item => {
                        return (<p style={{ color: 'black' }}>
                            {item}
                        </p>)
                    })
                }
            </div>
        </div>
    )
}

export default DescriptionTab
