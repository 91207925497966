import {
    TREND, RESULT_SELECTED, TREND_SELECTED
} from '../types/trend';
import axios from 'axios';
import clienteAxios from '../../config/axios';

//get data
export function getData(trend) {
    return async (dispatch) => {
        try {
            let response = '';
            response = await clienteAxios.get(`trend/${trend}`);
            response.data.trend[0].trendsNames = response.data.trendsNames
            dispatch(setData(response.data.trend[0]));
        } catch (error) {

        }
    }
}


//get data
export function selectTrend(trend) {
    return async (dispatch) => {
        try {
            dispatch(setTrend(trend));
        } catch (error) {
            console.log('ERROR', error);
        }
    }
}

//select result
export function selectResult(result) {
    return async (dispatch) => {
        try {
            dispatch(setResult(result));
        } catch (error) {

        }
    }
}

//set the data
const setData = (data) => ({
    type: TREND,
    payload: data
});


const setResult = (result) => ({
    type: RESULT_SELECTED,
    payload: result
});

const setTrend = (trend) => ({
    type: TREND_SELECTED,
    payload: trend
});