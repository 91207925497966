const ProcessData = (results) => {
    return results.filter(r => (r !== 'fail' && r !== 'empty')).map(r => {

        r.description = r.description_en ? r.description_en[0] :
            r.description_es ? r.description_es[0] :
                r.descriptions_en ? r.descriptions_en[0] :
                    r.descripcion ? r.descripcion[0] :
                        r.description ? r.description :
                            r.abstract_en ? r.abstract_en[0] :
                                r.summary ? r.summary[0] :
                                    r.full_text ? r.full_text[0] :
                                        r.description_es ? r.description_es[0] : '';

        r.title = r.title_en ? r.title_en[0] :
            r.title_es ? r.title_es[0] :
                r.name ? r.name[0] :
                    r.titulo ? r.titulo[0] :
                        r.invention_title ? (r.invention_title.length > 1 ? r.invention_title[1] : r.invention_title[0]) :
                            r.title ? r.title :
                                r.article_title ? r.article_title[0] :
                                    r.invention_title_en ? r.invention_title_en[0] : '';


        r.source = r.category === 'video' ? 'YouTube' :
            r.portal ? r.portal :
                r.journal_title ? r.journal_title[0] :
                    r.fuente ? r.fuente : '';

        r.datePublication = r.pubDate ? r.pubDate :
            r.date_publ ? r.date_publ[0] :
                r.pubdate ? r.pubdate[0] :
                    r.publishedAt ? r.publishedAt :
                        r.fecha ? r.fecha :
                            r.pub_date_epub ? r.pub_date_epub[0] :
                                r.date_produced ? r.date_produced : '';

        r.thumbnail = r.thumbnails ? r.thumbnails :
            r.picture ? r.picture[0] :
                r['thumbnails.high.url'] ? r['thumbnails.high.url'][0] :
                    r.thumbnail ? r.thumbnail : '';

        return r;

    });
}
export default ProcessData;