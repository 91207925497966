import React, { useEffect, useState } from 'react'
import DatosTab from './datosTab/Datos.jsx'
import ClaimsTab from './claimsTab/ClaimsTab.jsx'
import GraphTab from './graphTab/GraphTab.jsx'
import DescriptionTab from './descriptionTab/DescriptionTab.jsx'
import './patent.css'

const Patent = ({ props }) => {
    const [tab, setTab] = useState('Datos')
    const [translate, setTranslate] = useState(false);
    const [patent, setPatent] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setPatent(props)
        setTranslate(false)
        setTab('Datos')
    }, [props.title])


    return (
        <div>
            {patent !== '' &&
                <div>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 headerArticleItem">
                            <div style={{ float: 'left', marginLeft: '20px', borderBottom: '1px solid gray', width: '95%' }}>
                                <div style={{ display: 'inline-flex', float: 'left', padding: '10px' }}>
                                    <img src={require(`../../../../images/ui/icons/patentFilter.png`).default} alt="Girl in a jacket" width="25" height="25" />
                                    <div style={{ float: 'left', marginLeft: '20px' }}>Patente</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <strong><p>{patent.hasOwnProperty('invention_title') && patent.invention_title[0]}</p></strong>
                            <strong><p>{patent.hasOwnProperty('invention_title_en') && patent.invention_title_en[0]}</p></strong>
                        </div>
                        {/* <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-2">
                        <button onClick={() => setOpen(true)} style={{ backgroundColor: 'var(--primaryBlueTwo)' }} className="btn btn-primary btn-sm">{translate ? 'Texto original' : 'Traducir al español'}</button>
                    </div> */}
                    </div>
                    <div className="row">
                        <ul style={{ display: 'inline-flex' }}>
                            <div style={{ width: '6%' }} className='besideLi'></div>
                            {
                                ['Datos', 'Descripción', 'Claims', 'Gráficos'].map(x => {
                                    return (
                                        <li onClick={() => setTab(x)} className={tab === x ? 'active' : ''}>{x}</li>
                                    );
                                })

                            }
                            <div style={{ width: '6%' }} className='besideLi'></div>
                        </ul>
                    </div>
                    <div className="row">
                        {tab === 'Datos' && <DatosTab patent={patent} translate={false} />}
                        {tab === 'Claims' && <ClaimsTab patent={patent} translate={false} />}
                        {tab === 'Gráficos' && <GraphTab patent={patent} translate={false} />}
                        {tab === 'Descripción' && <DescriptionTab patent={patent} translate={false} />}
                    </div>
                </div>
            }

            {/* <TranslateModal setTranslate={setTranslate} translate={translate} open={open} setOpen={setOpen} /> */}
        </div>
    )
}

export default Patent
