import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import ReactTooltip from "react-tooltip";
import { useHistory } from 'react-router-dom';
import { selectTrend } from '../../../redux/actions/trendAction';
import ItemsCarousel from 'react-items-carousel';
import trendsImages from '../../../constants/trendsImages'
import { useDispatch } from 'react-redux';
import './trends.css'


const Trends = () => {

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();

    const trends = useSelector(state => state.trend.trend.trendsNames);
    const [tooltip, setTooltip] = useState('')

    const sendResultToStore = (result) => {
        window.scroll(0, 0);
        dispatch(selectTrend(result))
        history.push(`/dash/${result}`);
    }

    return (<div style={{ marginTop: '5vh' }}>
        {trends !== undefined &&
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <ReactTooltip id="trendName" place="top" effect="solid">
                    {tooltip}
                </ReactTooltip>
                {trends.length === 0 ?
                    <p>No se encontraron tendencias</p> :
                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={6}
                        gutter={20}
                        leftChevron={<div className="paginateLeft"><img style={{ marginBottom: '8px', transform:'translate(-20px)' }} src={require(`../../../images/ui/leftRow.png`).default} width={10} height={20} /></div>}
                        rightChevron={<div className="paginateRight"><img style={{ marginBottom: '8px', transform:'translate(20px)' }} src={require(`../../../images/ui/rightRow.png`).default} width={10} height={20} /></div>}
                        outsideChevron
                        chevronWidth={40}
                        infiniteLoop={true}
                    >
                        {trendsImages.map(trend => {
                            return (
                                <div onClick={() => sendResultToStore(trend.name)} style={{ cursor: 'pointer', height: 'auto' }}>

                                    <img onMouseOver={() => setTooltip(trend.name)} data-tip data-for="trendName" className="trendImage" src={require(`../../../images/trends/${trend.pathImage}.png`).default} width="100%" height="100%" />
                                    <div className="trendTitle"><strong>{trend.name.toUpperCase()}</strong></div>
                                </div>

                            );
                        })}
                    </ItemsCarousel>
                }
            </div>
        }
    </div>);
}
export default Trends