import React, { useState, useEffect } from 'react';
//import './App.css';

function BBB() {

  const [urlMeeting, setUrlMeeting] = useState('');

  useEffect(() => {
    bigBlueButton();
  }, [])


  const bigBlueButton = () => {
    const bbb = require('bigbluebutton-js')

    // BBB_URL and BBB_SECRET can be obtained
    // by running bbb-conf --secret on your BBB server
    // refer to Getting Started for more information
    let api = bbb.api(
      // process.env.BBB_URL, 
      // process.env.BBB_SECRET
      'https://bbb.examen.club/bigbluebutton/',
      '4JeA9BqnJMl2lcDmjC5NsuYfsJRq6i5KRNlGpvOicQ'
    )
    let http = bbb.http

    // api module itslef is responsible for constructing URLs
    let meetingCreateUrl = api.administration.create('My Meeting', '1', {
      duration: 10,
      attendeePW: 'secret',
      moderatorPW: 'supersecret',
    })

    // http method should be used in order to make calls
    http(meetingCreateUrl).then((result) => {
      console.log(result)

      let moderatorUrl = api.administration.join('moderator', '1', 'supersecret')
      let attendeeUrl = api.administration.join('attendee', '1', 'secret')
      console.log(`Moderator link: ${moderatorUrl}\nAttendee link: ${attendeeUrl}`)
      console.log(`${moderatorUrl}\nAttendee link: ${attendeeUrl}`)
      setUrlMeeting(`${moderatorUrl}`)
      let meetingEndUrl = api.administration.end('1', 'supersecret')
      console.log(`End meeting link: ${meetingEndUrl}`)
      //window.open(`${moderatorUrl}`);

      // fetch(`${moderatorUrl}\nAttendee link: ${attendeeUrl}`)
      //   .then(response => console.log('response', response))
      //   .then(data => console.log(data));
    })

  }


  return (
    <div>
      <div onClick={bigBlueButton}>meet</div>
      <iframe src={urlMeeting} width="400" height="400" allow="camera; microphone; display-capture; autoplay; clipboard-write; fullscreen">
      </iframe>
    </div>
  );
}

export default BBB;
