import {
    TREND, RESULT_SELECTED,
    TREND_SELECTED
} from '../types/trend';

//state
const initialState = {
    trend: [],
    resultSelected: {},
    trendSelected: ''
}

export default function (state = initialState, action) {
    switch (action.type) {

        case TREND:
            return {
                ...state,
                trend: action.payload,
            }

        case TREND_SELECTED:
            return {
                ...state,
                trendSelected: action.payload,
            }

        case RESULT_SELECTED:
            return {
                ...state,
                resultSelected: action.payload,
            }

        default:
            return state;
    }
}