import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {loginAction,loginTokenAction} from '../../redux/actions/authAction'
import { useSelector } from 'react-redux';
import './login.css'

const Login = () => {

    const auth = useSelector(state => state.auth.autenticated);
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState('');
    const [formValues, setformValues] = useState({
        email: '',
        password: '',
    });

    useEffect(()=>{
        if(auth){
            history.push('/carrousel');
        }
        const pathname = window.location.pathname
        if (pathname.length > 20){
           dispatch(loginTokenAction(pathname));
        }
    },[auth])


    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }


    const validateData = (event) => {
        event.preventDefault();
        let internalError = '';
        if (formValues.email === '' || formValues.email === undefined) {
            internalError = 'El usuario es requerido, ';
        }
        if (formValues.password === '' || formValues.password === undefined) {
            internalError = internalError + 'La contraseña es requerida, ';
        }
        if (internalError === '') {
            setError('');
            dispatch(loginAction(formValues));
        } else {
            setError(internalError);
        }
    }



    return (
        <div className="loginMain">
            <div className="loginBox">
            <strong><h4 className="welcome">BIENVENIDO</h4></strong>
                <strong><h4 className="diagnosis">OBSERVATORIO DE TENDENCIAS</h4></strong>
                <form onSubmit={validateData}>
                    <div style={{ display: 'inline-flex'}}>
                        <input style={{ borderRadius: '5px' }} className="inputs" placeholder="Email" name="email" value={formValues.email} onChange={handleInputChange} type="text" />
                    </div>
                    <br />
                    <br />
                    <div style={{ display: 'inline-flex' }}>
                        <input style={{ borderRadius: '5px' }} className="inputs" placeholder="Contraseña" name="password" value={formValues.password} onChange={handleInputChange} type="password" />
                    </div>
                    <br />
                    <br />
                    {error !== '' && <div>{error}</div>}
                    <br />
                    <button type="submit" className="btn btn-primary sendLogin">Ingrese a la plataforma</button>
                </form>
            </div>
            <div className="footerLogin">
                <img style={{ transform: 'translate(0, 10px)' }} src={require('../../images/ui/footerCarrousel.png').default} alt="" width="100%" />
            </div>
        </div>

    )
}

export default Login;
