import React from 'react'
import './startup.css'

const Startups = ({props}) => {
    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12 headerArticleItem">
                    <div style={{ float: 'left', marginLeft: '20px', borderBottom: '1px solid gray', width: '95%' }}>
                        <div style={{display:'inline-flex', float:'left', padding:'10px'}}>
                            <img src={require(`../../../../images/ui/icons/startupFilter.png`).default} alt="Girl in a jacket" width="25" height="25" />
                            <div style={{ float: 'left', marginLeft:'20px' }}>Startup</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="titleItemStartup"><strong>{props.title}</strong></div>
                <div className="descriptionStartupItem">
                    {props.description[0]}
                </div>
            </div>
        </div>
    )
}

export default Startups
