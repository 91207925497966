import axios from 'axios';

var axiosLogin = axios;
if(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === ""){
    axiosLogin = axios.create({
        //baseURL:'http://127.0.0.1:8001/api/'
        baseURL:'http://lantiasas.com/lantialogin/api/'
    });
}else{
    axiosLogin = axios.create({
        baseURL:'http://lantiasas.com/lantialogin/api/'
    });
}

export default axiosLogin;