import React from 'react'
import Skeleton from "react-loading-skeleton";
import './industries.css'

const Industries = () => {
    return (
        <div className="industries">
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
            <Skeleton style={{marginRight:'10px', borderRadius:'10px'}} height={20} width={20} />
        </div>
    )
}

export default Industries
