import React,{useEffect,useState} from 'react'
import './graph.css';

const GraphTab = ({translate, patent}) => {
    
    const [newImages,setNewImages] = useState('');

    useEffect(() => {        
        setImages();
    }, [])

    const setImages = () =>{  
        var imagen = []
        if (patent.hasOwnProperty('drawings_file')) {
            patent.drawings_file.map(img => {
                if (patent.hasOwnProperty("source_code")) {
                    if (patent.source_code[0].toUpperCase() === 'EP') {
                        img = img.replace('/mnt/raid/EPO/Procesados/', "http://imgpatents.lantiasas.com/epo/imagenes/");
                        img = img.replace("tif", 'jpg');
                        imagen.push(img)
                    }
                } else if (img.includes('USPTO/descomprimidos')) {
                    img = img.replace('/mnt/raid/USPTO/descomprimidos/', "http://imgpatents.lantiasas.com/ustpo/grant/imagenes/");
                    img = img.replace("TIF", 'jpg');
                    imagen.push(img)
                }
                else if (patent.type[0].toLowerCase() === 'application') {
                    /* img = img.replace('/mnt/raid/USPTO/aplicacion/descomprimidos/',"http://imgpatents.lantiasas.com/uspto/aplication/imagenes/");
                    img = img.replace("TIF",'jpg');*/
                }
            });
        }
        setNewImages(imagen)
    }
    return (
        <div className="graphPatent">
               {newImages !== '' &&
               newImages.map(image=>{
                   return(
                       <div>
                           <img src={image} width="600" height="600" />
                       </div>
                   )
               })
               }
        </div>
    )
}

export default GraphTab
