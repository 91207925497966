import React from 'react'
import Skeleton from "react-loading-skeleton";
import './greenBox.css'

const GreenBox = () => {
    return (
        <div className="greenBox">
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'10px'}} height={10} width={'90%'} />
            <Skeleton style={{marginRight:'100px'}} height={10} width={'90%'} />
        </div>
    );
}
export default GreenBox