const trendsImages = [
    {'name': 'Nuevo Proteccionismo', 'pathImage':'nuevoProteccionismo'},
    {'name': 'HUMANOS MEJORADOS CON TECNOLOGÍA ', 'pathImage':'humanosMejorados'},
    {'name': 'MADURACIÓN DE LA GENERACIÓN Z', 'pathImage':'maduracionZ'},
    {'name': 'DESCARBONIZACIÓN', 'pathImage':'descarbonizacion'},
    {'name': 'INNOVACIÓN DOMÉSTICA', 'pathImage':'innovacionDomestica'},
    {'name': 'INTERACCIÓN TOTAL', 'pathImage':'interaccionTotal'},
    {'name': 'INFRAESTRUCTURA LÍQUIDA', 'pathImage':'infraestructuraLiquida'},
    {'name': 'ECONOMÍA DEL COMPORTAMIENTO', 'pathImage':'economiaComportamiento'},
    {'name': 'IMPACTOS CLIMÁTICOS EXPONENCIALES', 'pathImage':'cambiosClimaticos'},
    {'name': 'EN TODAS PARTES, EN TODO MOMENTO', 'pathImage':'todasPartes'},
    {'name': 'TRABAJO Y VIDA ILIMITADOS', 'pathImage':'trabajoVidailimitados'},
    {'name': 'MATERIALES MÁS VERDES', 'pathImage':'materialesVerdes'},
    {'name': 'Economía Circular', 'pathImage':'economiaCircular'},
    {'name': 'SURGIMIENTO DEL CPO - CHIEF PREPPER OFFICER', 'pathImage':'cpo'},
    {'name': 'BOMBA DE TIEMPO CUÁNTICA', 'pathImage':'bombaCuantica'},
    {'name': 'COMPUTACIÓN AFECTIVA', 'pathImage':'computacionAfectiva'},
    {'name': 'EL CRECIMIENTO DE LA EXCLUSIVIDAD', 'pathImage':'crecimientoExclusividad'},
    {'name': 'AMBIENTE DE BIENESTAR', 'pathImage':'ambienteBienestar'},
    {'name': 'CONTRATOS INTELIGENTES', 'pathImage':'contratosInteligentes'},
    {'name': 'RELACIÓN HUMANODIGITAL EN EL B2B', 'pathImage':'relacionHumanoDigital'},
    {'name': 'ATENCIÓN PERSONALIZADA', 'pathImage':'atencionPersonalizada'},
    {'name': 'PERSONALIZACIÓN EN EL APRENDIZAJE', 'pathImage':'personalizacionEducacion'},
    {'name': 'Los datos son el nuevo petróleo', 'pathImage':'nuevoPetroleo'},
    {'name': 'Infraestructura Reacondicionada', 'pathImage':'infraestructuraReacondicionada'},
    {'name': 'MATERIALES INTELIGENTES', 'pathImage':'materialesInteligentes'},
    {'name': 'Omnicanalidad', 'pathImage':'omnicanalidad'},
    {'name': 'Human Touch', 'pathImage':'humanTouch'},
    {'name': 'DIGITAL WORK - TRABAJO HOMBRE MÁQUINA', 'pathImage':'digitalWork'},
    {'name': 'Servitización (Anything as a Service - AaaS) ', 'pathImage':'servitizacion'},
    {'name': 'Fuerza de trabajo +', 'pathImage':'fuerzaTrabajo'},
    {'name': 'Aprendizaje Inmersivo', 'pathImage':'aprendizajeInmerso'},
    {'name': 'Plásticos Renovables', 'pathImage':'plasticosRenovables'},
    {'name': 'Fabricación aditiva de gran escala', 'pathImage':'fabricacionAditiva'},
    {'name': 'Materiales compuestos avanzados', 'pathImage':'materialesCompuestos'},
    {'name': 'NUEVAS CAPACIDADES 4.0', 'pathImage':'nuevasCapacidades'},
    {'name': 'DEMOCRATIZACIÓN TECNOLÓGICA', 'pathImage':'democratizacion'},
    {'name': 'TRANSFORMACIÓN CONJUNTA', 'pathImage':'transformacionConjunta'},
    {'name': 'COMPUTACIÓN PARA LA PRIVACIDAD', 'pathImage':'computacionPrivacidad'},
    {'name': 'TRANSACCIONES Y PAGOS ELECTRÓNICOS', 'pathImage':'transacciones'},
    {'name': 'ELECTRIFICACIÓN DE LA MOVILIDAD', 'pathImage':'electrificacion'},
    {'name': 'HOSPITAL A DOMICILIO', 'pathImage':'hospitalDomicilio'},
    {'name': 'MÁS ALLÁ DE LA RECUALIFICACIÓN', 'pathImage':'masAllaRecua'},
    {'name': 'METAVERSE MALL', 'pathImage':'metaverse'},
    {'name': 'TRANSFORMACIÓN DIGITAL INDUSTRIAL', 'pathImage':'transformacionDigital'},
    {'name': 'NUBE DISTRIBUIDA', 'pathImage':'nubeDistribuida'},
    {'name': 'NUEVOS RITUALES', 'pathImage':'nuevosRituales'},
    {'name': 'Futuro del pensamiento', 'pathImage':'futuroPensamiento'},
    {'name': 'Generación distribuida y prosumidores', 'pathImage':'generacionDistribuida'}
];
export default trendsImages;