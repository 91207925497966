import React,{useState} from 'react'
import './colorTitle.css'

const ColorTitle = ({name,color,icon, width, height, widthTitle}) => {

    return ( 
        <>
            <div>
                <div style={{ marginLeft: '-50px!important'}} className='row'>
                    <div style={{border:`2px solid ${color}`, width:'42px', height:'42px'}} className='imageColorTitle'>
                        <img src={require(`../../images/ui/icons/${icon}.png`).default} alt="" width={width} height={height}/>
                    </div>
                    <div style={{backgroundColor:color, width:`${widthTitle}px`}} className='titleColorTitle'>
                        <div className="titleOfBox">{name}</div> 
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default ColorTitle;