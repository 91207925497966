import React, { useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard'
import ShowResult from './components/dashboard/showResult/ShowResult'
import Carrousel from './components/carrousel/Carrousel'
import ProtectedRoute from './components/auth/routes/ProtectedRoute'
import Login from './components/login/Login'
import Meeting from './components/meeting/Meeting';
import BBB from './components/meeting/BBB';
import './App.css';

import { Provider } from 'react-redux';
import store from './redux/store';

function App() {

  const [meet, setmeet] = useState(false)

  // const meeting = () => {
  //   // history.push('/meeting');
  //   setmeet(!meet);
  // }
  return (
    <div className="App">
    
      {/* <div onClick={meeting} className="buttonMeet">
        
      </div> */}
      {/* {meet &&
        <div className="meet">
          <BBB />
        </div>
      } */}
      <BrowserRouter>
        <Provider store={store}>
          <Switch>
            <ProtectedRoute exact path="/dash/:trend" component={Dashboard} />
            <ProtectedRoute exact path="/ShowResult" component={ShowResult} />
            <ProtectedRoute exact path="/carrousel" component={Carrousel} />
            <ProtectedRoute exact path="/meeting" component={Meeting} />
            <Route path="/login" component={Login} />
          </Switch>
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
