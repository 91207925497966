import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { selectResult } from '../../../redux/actions/trendAction'
import { useHistory } from 'react-router-dom';
import ItemsCarousel from 'react-items-carousel';
import './leadersCarrousel.css'

const LeadersCarrousel = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const _leaders = useSelector(state => state.trend.trend.leaders);
    //const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [leaders, setLeaders] = useState('')

    useEffect(() => {
        if (_leaders !== undefined) {
            setLeaders(_leaders)
        }
    }, [_leaders])

    const next = (next) => {
        try {
            if (next === 'up') {
                let last = leaders[leaders.length - 1]
                let pushed = leaders.slice(0, leaders.length - 1)
                pushed.unshift(last)
                setLeaders(pushed)
                return;
            }
            let first = leaders[0]
            let pushed = leaders.slice(1, leaders.length)
            pushed.push(first)
            setLeaders(pushed)
        } catch (error) {
        }

    }

    return (<div>
        <div>
            {leaders !== '' &&
                <div style={{ height: 'auto', paddingTop:'50px' }}>
                    {leaders.length === 0 ?
                        <p>No se encontraron Startups relacionados</p> :
                        <div>
                            
                            {leaders.slice(0, 1).map((leader) => {
                                return (
                                    <div style={{ height: 'auto', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ padding: '10px', display: 'inline-flex', margin: '0 auto' }}>
                                            {leader.path !== 'path' &&
                                                <div style={{ alignItems: 'center', paddingTop:'37px' }}>
                                                    <img src={require(`../../../images/leaders/${leader.path}`).default} width={leader.width} height={leader.height} />
                                                </div>
                                            }
                                            <div style={{ width: '70%' }}>
                                            <p style={{ marginBottom: '10px', lineHeight: '20px', color: '#0073af', fontWeight: '500' }}>{leader.name}</p>
                                                <p style={{ textAlign: 'justify', lineHeight: '20px' }}>{leader.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <div style={{ display: 'inline-flex' }}>
                                <div style={{ marginRight: '10px', cursor:'pointer' }} onClick={() => next('up')}><div ><img style={{ marginBottom: '8px' }} src={require(`../../../images/ui/upRow.png`).default} width={10} height={8} /></div></div>
                                <div onClick={() => next('down')}><img style={{ marginBottom: '8px', cursor:'pointer' }} src={require(`../../../images/ui/downRow.png`).default} width={10} height={8} /></div>
                            </div>
                        </div>
                    }
                </div>
            }

        </div>
    </div>);
}
export default LeadersCarrousel;