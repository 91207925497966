const trends =  [
    { 'name': 'Nuevo Proteccionismo', 'pathImage': 'nuevoProteccionismo', 'category': 1, 'description': 'Es un fenómeno de proteccionismo que se profundiza por los acontecimientos presentados durante la pandemia COVID19, que está cambiando los cimientos de la globalización. ' },
    { 'name': 'HUMANOS MEJORADOS CON TECNOLOGÍA ', 'pathImage': 'humanosMejorados', 'category': 4, 'description': 'Es la forma como se está generando una nueva infraestructura de sensado, procesamiento y transmisión de información alrededor de las personas.' },
    { 'name': 'MADURACIÓN DE LA GENERACIÓN Z', 'pathImage': 'maduracionZ', 'category': 1, 'description': 'Es el proceso de cambio que se está dando por la maduración y activación a la vida productiva de la generación Z.' },
    { 'name': 'DESCARBONIZACIÓN', 'pathImage': 'descarbonizacion', 'category': 1, 'description': 'Es una transformación caracterizada por el esfuerzo global para eliminar o reducir las emisiones de gases de efecto invernadero' },
    { 'name': 'INNOVACIÓN DOMÉSTICA', 'pathImage': 'innovacionDomestica', 'category': 4, 'description': 'Es la extensión de la innovación al ámbito doméstico, reflejado especialmente en la transformación de espacios vitales y la modificación de objetos de la vida diaria para adaptarlos a las nuevas necesidades.' },
    { 'name': 'INTERACCIÓN TOTAL', 'pathImage': 'interaccionTotal', 'category': 5, 'description': 'Es la forma en que las organizaciones prestan especial atención al diseño, el contenido, la audiencia y la interacción, en el diseño de herramientas digitales.' },
    { 'name': 'INFRAESTRUCTURA LÍQUIDA', 'pathImage': 'infraestructuraLiquida', 'category': 4, 'description': 'Es la nueva forma en que las empresas deben pensar sus infraestructuras y activos, para satisfacer las nuevas necesidades y demandas de los consumidores.' },
    { 'name': 'ECONOMÍA DEL COMPORTAMIENTO', 'pathImage': 'economiaComportamiento', 'category': 5, 'description': 'Es la cuantificación, estandarización, empaquetamiento y comercialización del comportamiento humano, al igual que los datos de los consumidores en la actualidad.' },
    { 'name': 'IMPACTOS CLIMÁTICOS EXPONENCIALES', 'pathImage': 'cambiosClimaticos', 'category': 1, 'description': 'Es el inicio de una nueva era marcada por impactos climáticos extremos, volátiles y disruptivos en cuanto a su impredecibilidad.' },
    { 'name': 'EN TODAS PARTES, EN TODO MOMENTO', 'pathImage': 'todasPartes', 'category': 4, 'description': 'Es la independencia física con la que cuentan los trabajadores para realizar sus actividades laborales, gozando de libertad para trabajar desde cualquier lugar y en todo momento.' },
    { 'name': 'TRABAJO Y VIDA ILIMITADOS', 'pathImage': 'trabajoVidailimitados', 'category': 4, 'description': 'Es la transición del trabajo y la vida reequilibrando repetidamente su combinación entre el estudio, el empleo y el ocio de acuerdo con las circunstancias cambiantes de la vida individual.' },
    { 'name': 'MATERIALES MÁS VERDES', 'pathImage': 'materialesVerdes', 'category': 2, 'description': 'Son los materiales ecológicos recién sintetizados o modificados, que poseen propiedades superiores y especiales.' },
    { 'name': 'Economía Circular', 'pathImage': 'economiaCircular', 'category': 2, 'description': 'Es la forma en la que se gestionan las existencias producidas (capital físico),  equilibrando el uso de activos humanos, materiales, naturales y financieros. ' },
    { 'name': 'SURGIMIENTO DEL CPO - CHIEF PREPPER OFFICER', 'pathImage': 'cpo', 'category': 4, 'description': 'Es el cómo la resiliencia se convertirá en una de las funciones corporativas más destacadas.' },
    { 'name': 'BOMBA DE TIEMPO CUÁNTICA', 'pathImage': 'bombaCuantica', 'category': 3, 'description': 'Es la suma de todos los posibles cambios que generará la computación cuántica.' },
    { 'name': 'COMPUTACIÓN AFECTIVA', 'pathImage': 'computacionAfectiva', 'category': 3, 'description': 'Es la priorización de las empresas a la creación de tecnologías de inteligencia artificial que puedan interpretar y responder a las emociones humanas.' },
    { 'name': 'EL CRECIMIENTO DE LA EXCLUSIVIDAD', 'pathImage': 'crecimientoExclusividad', 'category': 5, 'description': 'Es cómo las redes de exclusividad se convertirán en el futuro de las redes de interacción social.' },
    { 'name': 'AMBIENTE DE BIENESTAR', 'pathImage': 'ambienteBienestar', 'category': 4, 'description': 'Es la desvinculación y traslado del spa hacia los hogares, las tiendas y otros espacios, debido a la continua convergencia de la salud y el autocuidado.' },
    { 'name': 'CONTRATOS INTELIGENTES', 'pathImage': 'contratosInteligentes', 'category': 3, 'description': 'Es un acuerdo entre dos partes que se almacena en cadenas de bloques.' },
    { 'name': 'RELACIÓN HUMANODIGITAL EN EL B2B', 'pathImage': 'relacionHumanoDigital', 'category': 5, 'description': 'Es la migración a esquemas de venta que optimizan la compra y la adquisición de los productos o servicios de la empresa.' },
    { 'name': 'ATENCIÓN PERSONALIZADA', 'pathImage': 'atencionPersonalizada', 'category': 5, 'description': 'Es la creación de una experiencia que llegue a satisfacer y superar las necesidades y expectativas del cliente, gracias al conocimiento profundo de éste.' },
    { 'name': 'PERSONALIZACIÓN EN EL APRENDIZAJE', 'pathImage': 'personalizacionEducacion', 'category': 4, 'description': 'Es un cambio a un modelo más individual y flexible, apalancado con tecnología, que permitirá obtener mejores resultados en los aprendices.' },
    { 'name': 'Los datos son el nuevo petróleo', 'pathImage': 'nuevoPetroleo', 'category': 3, 'description': 'Es la visión de los datos como el ADN de la organización conectada del siglo XXI, donde son estos  los encargados de impulsar la conectividad entre todos los interesados.' },
    { 'name': 'Infraestructura Reacondicionada', 'pathImage': 'infraestructuraReacondicionada', 'category': 2, 'description': 'Es la forma en la que se está dando una nueva vida a las infraestructuras deterioradas empleando materiales compuestos.' },
    { 'name': 'MATERIALES INTELIGENTES', 'pathImage': 'materialesInteligentes', 'category': 2, 'description': 'Son un tipo de materiales diseñados para cumplir con una función altamente especializada, manipulados para responder de forma controlable y reversible.' },
    { 'name': 'Omnicanalidad', 'pathImage': 'omnicanalidad', 'category': 3, 'description': 'Es el uso de la digitalización y de las diferentes tecnologías emergentes, con el objetivo de brindar constante disponibilidad a los interesados.' },
    { 'name': 'Human Touch', 'pathImage': 'humanTouch', 'category': 5, 'description': 'Es la necesidad de los consumidores de continuar con la interacción humana en un mundo donde prima lo digital.' },
    { 'name': 'DIGITAL WORK - TRABAJO HOMBRE MÁQUINA', 'pathImage': 'digitalWork', 'category': 5, 'description': 'Es la interacción hombre-máquina, que permitirá a las personas concentrarse en la creación y dejará a las máquinas la repetición y la fuerza bruta.' },
    { 'name': 'Servitización (Anything as a Service - AaaS) ', 'pathImage': 'servitizacion', 'category': 3, 'description': 'Corresponde a modelos de negocio que ofrecen una variedad de productos, servicios y aplicaciones a los usuarios usando la infraestructura del proveedor.' },
    { 'name': 'Fuerza de trabajo +', 'pathImage': 'fuerzaTrabajo', 'category': 5, 'description': 'Corresponde a la forma como se está repensando la evaluación de capacidades de los colaboradores desde el punto de vista de sus habilidades tecnológicas.' },
    { 'name': 'Aprendizaje Inmersivo', 'pathImage': 'aprendizajeInmerso', 'category': 5, 'description': 'El aprendizaje inmersivo consiste en llegar a una mayor cantidad de personas a través de un entorno en el que intervengan los cinco sentidos.' },
    { 'name': 'Plásticos Renovables', 'pathImage': 'plasticosRenovables', 'category': 2, 'description': 'Corresponde a la tendencia a nivel mundial a sustituir las materias primas necesarias para la creación de plásticos de forma que se utilicen cada vez más fuentes renovables.' },
    { 'name': 'Fabricación aditiva de gran escala', 'pathImage': 'fabricacionAditiva', 'category': 3, 'description': 'Es un enfoque transformador de la producción industrial que permite la creación de piezas y sistemas más ligeros y resistentes mediante la agregación de capas de material.' },
    { 'name': 'Materiales compuestos avanzados', 'pathImage': 'materialesCompuestos', 'category': 3, 'description': 'Es una combinación, desde el proceso de diseño, de varios materiales que da como resultado otro con mejores y más avanzadas propiedades que las de los elementos que lo conforman.' },
    { 'name': 'NUEVAS CAPACIDADES 4.0', 'pathImage': 'nuevasCapacidades', 'category': 3, 'description': 'Es la forma como las empresas aprovechan el verdadero valor de alinear la tecnología y la estrategia, al aprovechar especialmente las llamadas tecnologías 4,0.' },
    { 'name': 'DEMOCRATIZACIÓN TECNOLÓGICA', 'pathImage': 'democratizacion', 'category': 5, 'description': 'Es la autonomía de todas las personas en una empresa para desarrollar e implementar soluciones de software que les permita optimizar su trabajo o fortalecer sus propios puntos débiles. ' },
    { 'name': 'TRANSFORMACIÓN CONJUNTA', 'pathImage': 'transformacionConjunta', 'category': 4, 'description': 'Es la necesidad de las empresas de progresar de manera conjunta con relaciones de confianza que beneficie a las diferentes partes para crecer juntas.' },
    { 'name': 'COMPUTACIÓN PARA LA PRIVACIDAD', 'pathImage': 'computacionPrivacidad', 'category': 3, 'description': 'Es un conjunto de tecnologías que protegen los datos mientras se utilizan, y que permiten un procesamiento y un análisis seguro.' },
    { 'name': 'TRANSACCIONES Y PAGOS ELECTRÓNICOS', 'pathImage': 'transacciones', 'category': 4, 'description': 'Es la forma como en los países de todo el mundo se han puesto en marcha actualizaciones de sus sistemas  de intercambio monetario.' },
    { 'name': 'ELECTRIFICACIÓN DE LA MOVILIDAD', 'pathImage': 'electrificacion', 'category': 2, 'description': 'Es el uso de diferentes tecnologías que permiten facilitar la propulsión eléctrica de los vehículos y flotas de estos.' },
    { 'name': 'HOSPITAL A DOMICILIO', 'pathImage': 'hospitalDomicilio', 'category': 5, 'description': 'Es la forma como se ha diseñado una oferta de atención de nivel hospitalario en hogares de los pacientes, aprovechando la telesalud e incentivando el uso de servicios sanitarios en el hogar.' },
    { 'name': 'MÁS ALLÁ DE LA RECUALIFICACIÓN', 'pathImage': 'masAllaRecua', 'category': 5, 'description': 'Es la forma como los colaboradores han tenido que ampliar sus funciones en áreas que antes no atendían regularmente.' },
    { 'name': 'METAVERSE MALL', 'pathImage': 'metaverse', 'category': 3, 'description': 'Es la redefinición de los espacios virtuales compartidos, y cómo éstos afectarán la forma en que compramos e interactuamos con las personas y las empresas.' },
    { 'name': 'TRANSFORMACIÓN DIGITAL INDUSTRIAL', 'pathImage': 'transformacionDigital', 'category': 3, 'description': 'Es la capacidad de capitalizar un cambio hacia la industria 4.0 y de manera efectiva crear y monetizar soluciones e IIoT (Industrial Internet of Things). ' },
    { 'name': 'NUBE DISTRIBUIDA', 'pathImage': 'nubeDistribuida', 'category': 3, 'description': 'Es un tipo de infraestructura geográficamente dispersa que principalmente ejecuta servicios en el borde de la red, diferente del modelo de nube  que se basa en un centro de datos centralizado.' },
    { 'name': 'NUEVOS RITUALES', 'pathImage': 'nuevosRituales', 'category': 4, 'description': 'Es el cambio de costumbres, símbolos, hábitos y experiencias emocionales compartidas mediante tradiciones asociadas con pérdidas, celebraciones, ceremonias y rutinas que seguía la población antes de la pandemia.' },
    { 'name': 'Futuro del pensamiento', 'pathImage': 'futuroPensamiento', 'category': 5, 'description': 'Es la forma en que la tecnología está cambiando profundamente la forma de pensar y actuar de las personas, las organizaciones y los gobiernos' },
    { 'name': 'Generación distribuida y prosumidores', 'pathImage': 'generacionDistribuida', 'category': 3, 'description': 'Es la transformación de los consumidores que aprovechan la energía solar y eólica para convertirse en generadores de energía de manera similar a las empresas de servicios públicos.' }
  ];
  export default trends;