import React, { useState, useEffect } from 'react'

const New = ({ props }) => {


    const [descriptionText, setDescription] = useState('')
    // const [translate, setTranslate] = useState(false);
    const [noticia, setNoticia] = useState('')
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // // if (translate) {
        // //     translateText();
        // //     return;
        // // }
        setNoticia(props);
        //setDescription(props.description.split('\n'))
    }, [props.description /*translate*/])

    // const translateText = () => {
    //     translateNew(description).then(response => {
    //         setDescription(response)
    //     });
    // }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12 headerArticleItem">
                    <div style={{ float: 'left', marginLeft: '20px', borderBottom: '1px solid gray', width: '95%' }}>
                        <div style={{display:'inline-flex', float:'left', padding:'10px'}}>
                            <img src={require(`../../../../images/ui/icons/newsFilter.png`).default} alt="Girl in a jacket" width="25" height="25" />
                            <div style={{ float: 'left', marginLeft:'20px' }}>Noticia</div>
                        </div>
                    </div>
                </div>
            </div>
            {noticia !== '' &&
                <div>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <br/>
                            <p style={{ color: 'black', paddingLeft:'20px', fontSize: '23px' }}>{noticia.title}</p>
                        </div>
                        {/* <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-2">
                    <button onClick={() => setOpen(true)} style={{ backgroundColor: 'var(--primaryBlueTwo)' }} className="btn btn-primary btn-sm">{translate ? 'Texto original' : 'Traducir al español'}</button>
                </div> */}
                    </div>
                    <div style={{ padding: '50px' }} className="row">
                        {/* <div className="item col-12 col-md-12 col-lg-12"><h6>Portal: </h6><p>{props.source}</p></div> */}

                        {/* <div className="item col-12 col-md-6 col-lg-6"><h6>Fecha de publicación: </h6><p>{props.date[0].substr(0, 10)}</p></div> */}
                        <div className="item col-12 col-md-6 col-lg-6">
                            <div className="redirect">
                                {/* <img src={require('../../../../images/ui/icons/redirectIcon.png')} style={{ width: '20px', height: '20px' }} alt='' /> */}
                                {/* <a target="_blank" style={{ textDecoration: 'none' }} href={props.link}>Ir al portal</a> */}
                            </div>
                        </div>

                        {noticia.image && <img src={noticia.image} alt="alt text" style={{ float: 'right' }}></img>}
                        {noticia.description !== '' &&
                            (noticia.description.split('\n')).map(paragraph => {
                                return (
                                    <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                        {paragraph}
                                    </p>
                                );
                            })}

                    </div>
                </div>

            }


            {/* <TranslateModal setTranslate={setTranslate} translate={translate} open={open} setOpen={setOpen} /> */}
        </div>
    )
}

export default New
