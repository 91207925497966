import React, { useEffect, useState } from 'react'
import trends from '../../constants/trends'
import { useHistory } from 'react-router-dom';
import { selectTrend } from '../../redux/actions/trendAction';
import { useDispatch } from 'react-redux';
import Meeting from '../meeting/Meeting';
import './carrousel.css'

const Carrousel = () => {

    const history = useHistory();
    const [filterTrends, setFilterTrends] = useState('');
    const [area, setArea] = useState({ value: '1Megatendencias', name: 'Megatendencias' });
    const dispatch = useDispatch();

    useEffect(() => {
        setFilterTrends(trends.filter(trend => trend.category === 1));
    }, [])

    const handleSelectChange = (event) => {
        setArea({ value: event.target.value, name: event.target.value.substr(1, event.target.value.length) })
        console.log(trends.filter(trend => trend.category == event.target.value.substr(0, 1)));
        setFilterTrends(trends.filter(trend => trend.category == event.target.value.substr(0, 1)));
    }

    const next = (value) => {
        try {
            console.log(value)
            console.log('filterTrends', filterTrends);
            if (value) {
                let first = filterTrends[0]

                let newOrder = []

                for (var i = 0; i < filterTrends.length; i++) {
                    if (i > 0) {
                        newOrder.push(filterTrends[i])
                    }
                }

                newOrder.push(first)
                //filterTrends = newOrder

                setFilterTrends(newOrder)
                console.log('newOrder ', newOrder);

            } else {
                let first = filterTrends[filterTrends.length - 1]

                let newOrder = []

                for (var i = 0; i < filterTrends.length; i++) {
                    if (i < filterTrends.length - 1) {
                        newOrder.push(filterTrends[i])
                    }
                }

                newOrder.unshift(first)
                //filterTrends = newOrder
                setFilterTrends(newOrder)
                console.log('newOrder ', newOrder);
            }

        } catch (error) {
            console.log('error', error);
        }

    }

    const setTrend = (trend) => {
        dispatch(selectTrend(trend))
        history.push(`/dash/${trend}`);
    }



    return (
        <div className="carrouselComponent">
            <div className='returnPl'>
                <a style={{textDecoration:'none', color:'#fff'}} href="https://lantiasas.com/homeApp">Regresar a la Plataforma lantia</a>
            </div>

            <div class="headerCarrousel">
                <h1>OBSERVATORIO DE TENDENCIAS</h1>
            </div>
            <div class="row paragraphHeader">
                <p>Bienvenido al observatorio de tendencias, a continuación encontrarás las fichas de cada tendencia, usa el filtro
                    para navegar con facilidad.</p>
            </div>
            <div class="categoriesBox">
                <select value={area} onChange={handleSelectChange}>
                    <option value={area.value}>{area.name}</option>
                    <option value="2Sostenibilidad">Sostenibilidad</option>
                    <option value="1Megatendencias">Megatendencias</option>
                    <option value="3Tecnologías Disruptivas">Tecnologías Disruptivas</option>
                    <option value="4Nuevas Formas,Nuevos Modos">Nuevas Formas,Nuevos Modos</option>
                    <option value="5Toque Humano">Toque Humano</option>
                </select>

            </div>
            <br />
            <br />
            {filterTrends !== '' &&
                <div class="carrousel">
                    <div style={{ cursor: 'pointer', display: 'inline-flex', marginRight: '10px' }} onClick={() => next(false)}>
                        <img src={require('../../images/ui/leftRow.png').default} alt="" width="50px" height="80px" />
                        <img src={require('../../images/ui/leftRow.png').default} alt="" width="50px" height="80px" />
                    </div>
                    <div className="itemCarrousellast">
                        <div style={{ fontSize: '10px', paddingBottom: '20px' }} className="headerItem">
                            {filterTrends[0] !== undefined && filterTrends[0].name.toUpperCase()}
                        </div>
                        <div className="bodyItemFirst">
                            <img src={require(`../../images/trends/${filterTrends[0] !== undefined && filterTrends[0].pathImage}.png`).default} alt="" />
                        </div>
                        <div className="descriptionTrend">
                            <p>{filterTrends[0] !== undefined && filterTrends[0].description.substr(0, 20)}...</p>
                        </div>
                    </div>
                    <div className="itemCarrousel">
                        <div style={{ fontSize: '12px' }} class="headerItem">{filterTrends[1] !== undefined && filterTrends[1].name.toUpperCase()}</div>
                        <div className="bodyItem">
                            <img src={require(`../../images/trends/${filterTrends[1] !== undefined && filterTrends[1].pathImage}.png`).default} alt="" />
                        </div>
                        <div className="descriptionTrend">
                            <p>{filterTrends[1] !== undefined && filterTrends[1].description.substr(0, 70)}...</p>
                        </div>
                    </div>
                    <div className="itemCarrouselFirst">
                        <div className="headerItem">{filterTrends[2] !== undefined && filterTrends[2].name.toUpperCase()}</div>
                        <div className="bodyItem">
                            <img src={require(`../../images/trends/${filterTrends[2] !== undefined && filterTrends[2].pathImage}.png`).default} alt="" />
                        </div>
                        <div className="descriptionTrend">
                            <p>{filterTrends[2] !== undefined && filterTrends[2].description}</p>
                        </div>
                        <div className="viewMoreTrend" type="button" data-toggle="modal" data-target="#exampleModal" onClick={() => setTrend(filterTrends[2].name)}>
                            EXPLORA LA TENDENCIA
                        </div>
                    </div>
                    <div className="itemCarrousel">
                        <div style={{ fontSize: '12px' }} className="headerItem">{filterTrends[3] !== undefined && filterTrends[3].name.toUpperCase()}</div>
                        <div className="bodyItem">
                            <img src={require(`../../images/trends/${filterTrends[3] !== undefined && filterTrends[3].pathImage}.png`).default} alt="" />
                        </div>
                        <div className="descriptionTrend">
                            <p>{filterTrends[2] !== undefined && filterTrends[2].description.substr(0, 70)}...</p>
                        </div>
                    </div>
                    <div className="itemCarrousellast">
                        <div style={{ fontSize: '10px', paddingBottom: '20px' }} className="headerItem">{filterTrends[4] !== undefined ? filterTrends[4].name.toUpperCase() : filterTrends[0].name.toUpperCase()}</div>
                        <div className="bodyItemFirst">
                            <img src={require(`../../images/trends/${filterTrends[4] !== undefined ? filterTrends[4].pathImage : filterTrends[0].pathImage}.png`).default} alt="" />
                        </div>
                        <div className="descriptionTrend">
                            <p>{filterTrends[4] !== undefined ? filterTrends[4].description.substr(0, 20) : filterTrends[0].description.substr(0, 20)}...</p>
                        </div>
                    </div>
                    <div style={{ cursor: 'pointer', display: 'inline-flex' }} onClick={() => next(true)}>
                        <img src={require('../../images/ui/rightRow.png').default} alt="" width="50px" height="80px" />
                        <img src={require('../../images/ui/rightRow.png').default} alt="" width="50px" height="80px" />
                    </div>
                </div>
            }
            <div className="footerCarrousel">
                <img src={require('../../images/ui/footerCarrousel.png').default} alt="" width="100%" />
            </div>
        </div>
    )
}

export default Carrousel
